import React, { useState, useEffect } from 'react';

const Navbar = ({ originalProduct , categories, category, setCategory, setProductData}) => {
  const [query, setQuery] = useState('');
  const [debouncedQuery, setDebouncedQuery] = useState(query);

  // Debounce effect: Update debouncedQuery after 300ms of inactivity
  useEffect(() => {
    const timerId = setTimeout(() => {
      setDebouncedQuery(query);
    }, 300);
    
    return () => clearTimeout(timerId); // Clean up the timeout if query changes before 300ms
  }, [query]);

  // Filter products based on debounced query and set the filtered products
  useEffect(() => {
    if (debouncedQuery) {
      let filteredData = originalProduct.filter(item =>
        item.name.toLowerCase().includes(debouncedQuery) || 
        item.category.toLowerCase().includes(debouncedQuery) || 
        item.type.toLowerCase().includes(debouncedQuery)
      );
      setProductData(filteredData);
    } else {
      setProductData(originalProduct);
    }
  }, [debouncedQuery, originalProduct, setProductData]);

  const handleInputChange = (e) => {
    setQuery(e.target.value.toLowerCase());
  };

  const clearSearch = () => {
    setQuery('');
    setProductData(originalProduct);
  };

  return (
    <nav className="navbar">
      <div className="navbar-filter">
        <div><p className="filter-category">Category:</p></div>
        <div>
          <select
            id="category"
            value={category}
            onChange={(e) => setCategory(e.target.value)}
          >
            {categories.map((cat) => (
              <option key={cat} value={cat}>
                {cat}
              </option>
            ))}
          </select>
        </div>
      </div>
      <div className="navbar-search">
        <i className="fa fa-search"></i>
        <input
          type="text"
          placeholder="Search products"
          value={query}
          onChange={handleInputChange}
        />
        {query && <button className="clear-btn" onClick={clearSearch}>x</button>}
      </div>
    </nav>
  );
};

export default Navbar;
