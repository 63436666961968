import React, { useEffect, useState, useMemo } from "react";
import { useLocation } from "react-router-dom";
import clothData from "../../data/product2.json";
import squareScarf from "../../data/square.json";
import rectangleScarf from "../../data/rectangle.json";
import styles from '../../Product.module.css';
import ActorsGrid from './components/ActorsGrid';
import ActorModal from './components/ActorModal';
import Navbar from './components/Navbar';
import './components/app.css';

export const Products = () => {
  const [productData, setProductData] = useState([]); // Initialize with an empty array
  const [allProductData, setAllProductData] = useState([]);
  const [category, setCategory] = useState('Show All'); // State to track selected category
  const [selectedActor, setSelectedActor] = useState(null);

  const location = useLocation(); // Get the current location

  // Fetch products data on component mount
  useEffect(() => {
    setAllProductData([...clothData.products, ...squareScarf.products, ...rectangleScarf.products]);
    setProductData([...clothData.products, ...squareScarf.products, ...rectangleScarf.products]); // Ensure this matches the structure of your JSON data
  }, []);

  // Memoize the categories to avoid recalculating them on every render
  const categories = useMemo(() => ['Show All', ...new Set(productData.map(product => product.category))], [productData]);

  // Set the category from the query parameter on component mount or location change
  useEffect(() => {
    // Move getQueryParams function inside the useEffect
    const getQueryParams = (param) => {
      return new URLSearchParams(location.search).get(param);
    };

    const queryCategory = getQueryParams('category'); // Get category from query params
    if (queryCategory && categories.includes(queryCategory)) {
      setCategory(queryCategory); // Set the category from the query param if it exists
    } else {
      setCategory('Show All'); // Default to 'Show All' if no valid category
    }
  }, [location.search, categories]); // No need to include getQueryParams in the dependency array

  // Filter the items based on the selected category
  const filteredItems = category === 'Show All' ? productData : productData.filter(item => item.category === category);

  return (
    <div id="team" className="text-center">
      <div className="container">
        <div className="row">
          <div className={`${styles['section-title']} col-md-8 col-md-offset-2`}>
            <h2>Our Products</h2>
            <p>100% silk products.</p>
          </div>
        </div>
        <Navbar
          originalProduct={allProductData}
          category={category}
          setCategory={setCategory}
          categories={categories}
          setProductData={setProductData}
        />
        <main>
          {filteredItems.length > 0 ? (
            <ActorsGrid actors={filteredItems} onActorClick={setSelectedActor} />
          ) : (
            <p>No product found.</p>
          )}
        </main>
        {selectedActor && (
          <ActorModal actor={selectedActor} onClose={() => setSelectedActor(null)} />
        )}
      </div>
    </div>
  );
};

export default Products;
