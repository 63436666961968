import React from 'react';
import './app.css';

const ActorModal = ({ actor, onClose }) => {
  if (!actor) return null;

  return (
    <div className="modal-overlay" onClick={onClose}>
      <div className="modal-content" onClick={(e) => e.stopPropagation()}>
        <button className="close-modal" onClick={onClose}>&times;</button>
        <div className="modal-body">
          <div className="modal-left">
            <h2>{actor.id??actor.name.split(".")[0]}</h2>
            <div className="watermark-container-2">
            <img
              src={actor.img}
              alt={actor.name}
            />
            <div className="watermark2 watermark-top-left">JM Silk</div>
              <div className="watermark2 watermark-bottom-right">JM Silk</div>
              <div className="watermark2 watermark-center">JM Silk</div>
            </div>
          </div>
          <div className="modal-right">
            <div className="actor-details">
              <p><strong>Category:</strong> {actor.category}</p>
              <p><strong>Type:</strong> {actor.type}</p>
              {/* Known for Movies/TV Shows
              <p><strong>Known for:</strong></p>
              <ul>
                {actor.known_for.map((movie, index) => (
                  <li key={index}>
                    <img
                      src={`https://image.tmdb.org/t/p/w200${movie.poster_path}`}
                      alt={movie.title || movie.name}
                      className="known-for-poster"
                    />
                    <span>{movie.title || movie.name}</span>
                  </li>
                ))}
              </ul> */}
            </div>
        </div>
        </div>
      </div>
    </div>
  );
};

export default ActorModal;
