import React from 'react';
import './app.css';
const ActorsGrid = ({ actors, onActorClick }) => {
  const gridClass = actors.length === 1 ? 'actors-grid center-single' : 'actors-grid';

  return (
    <div className="actor-container">      
      <div className={gridClass}>
      {actors.map((actor, index) => (
        <div className="actor-card" key={`${actor.id}-${index}`} onClick={() => onActorClick(actor)}>
          {/* <div className="priority-number">{actor.id}</div> */}
          <div className="watermark-container">
          <img
            src={actor.img}
            alt={actor.name}
            loading="lazy"
            className="watermarked-image"
          />
          {/* <div className="watermark">JM Silk</div> */}
          <div className="watermark watermark-top-left">JM Silk</div>
              <div className="watermark watermark-bottom-right">JM Silk</div>
              <div className="watermark watermark-center">JM Silk</div>
          </div>
          <div className="actor-info">
            <h3>{actor.id??actor.name.split(".")[0]}</h3>
          </div>
        </div>
      ))}
    </div>
    </div>
  );
};

export default ActorsGrid;
