import React from "react";

export const Catalog = (props) => {
  return (
    <div id="catalog" className="text-center">
      <div className="container">
        <div className="section-title">
          <h2>Our Catalog</h2>
          <p>
            Providing comprehensive OEM and ODM silk production, customized
            manufacturing, innovative design, strict quality control, and
            exceptional after-sales support.
          </p>
        </div>
        <div className="row">
          <div className="col-md-6">
            {/* <img className="service-image" src={d.image} alt={""} /> */}
            <div className="service-desc">
              <a
                href="https://jmsilk.s3.us-west-2.amazonaws.com/JM+Silk+Garment+Catalog.pdf"
                target="_blank"
                rel="noreferrer noopener"
              >
                <h3>JM Silk Garment Catalog 1</h3>
              </a>
              <a
                href="https://jmsilk.s3.us-west-2.amazonaws.com/JM+Silk+Garment+Catalog.pdf"
                target="_blank"
                rel="noreferrer noopener"
              >
                See details (File size is huge, it may take longer time to load)
              </a>
            </div>
          </div>
          <div className="col-md-6">
            {/* <img className="service-image" src={d.image} alt={""} /> */}
            <div className="service-desc">
              <h3>JM Silk Garment Catalog 2</h3>
              <a
                href="https://jmsilk.s3.us-west-2.amazonaws.com/JMsilk+Garment+catalog2.pdf"
                target="_blank"
                rel="noreferrer noopener"
              >
                See details
              </a>
            </div>
          </div>
          <div className="col-md-6">
            {/* <img className="service-image" src={d.image} alt={""} /> */}
            <div className="service-desc">
              <h3>JM Silk Eye mask/ Pillow</h3>
              <a
                href="https://jmsilk.s3.us-west-2.amazonaws.com/JMsilk+eye+mask%3A+pillow.pdf"
                target="_blank"
                rel="noreferrer noopener"
              >
                See details
              </a>
            </div>
          </div>
          <div className="col-md-6">
            {/* <img className="service-image" src={d.image} alt={""} /> */}
            <div className="service-desc">
              <h3>JM Silk Pajama</h3>
              <a
                href="https://jmsilk.s3.us-west-2.amazonaws.com/JMsilk+pajama.pdf"
                target="_blank"
                rel="noreferrer noopener"
              >
                See details
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
